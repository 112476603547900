.capellan-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.capellan-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 650px;
  font-family: "Roboto", sans-serif;
  font-size: x-large;
}

.capellan-status {
  width: 560px;
  font-size: xx-large;
  border: solid 3px #19397d;
  color: #19397d;
  font-weight: 700;
  margin: 20px;
}

.font-blue{
  color: #19397d;
}

.portrait {
  border: solid 5px #cbaa5b;
  border-top-left-radius: 22px;
  border-bottom-right-radius: 22px;
  margin: 20px;
  max-width: 120px;
}

.portrait img {
  border-top-left-radius: 17px;
  border-bottom-right-radius: 17px;
  width: 100%;
}

.info-row-name-wrapper {
  display: flex;
  width: 115px;
}

.info-row-name {
  width: 100%;
  text-align: right;
  font-weight: 700;
  border-right: solid 3px black;
  margin-right: 10px;
  padding-right: 5px;
}

.info-row-value {
  text-align: left;
  font-weight: 700;
  color: #19397d;
}

.info-row-name.chaplain-name {
  text-align: center;
  border: 0;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .capellan-status {
    width: auto;
    padding: 0 20px;
  }
  .portrait {
    max-width: 160px;
    margin: 20px auto;
  }
  .chaplain-name {
    text-align: center;
  }
  .info-row-name-wrapper {
    width: auto;
  }
  .info-row-name, .info-row-value {
    font-size: large;
  }
}