.App {
  text-align: center;
}

.App-logo {
  height: 40vh;
  pointer-events: none;
}

.navbar {
  font-family: "Roboto", sans-serif;
  background-color: #19397d;
  padding: 0.5rem 1rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.page-content {
	min-width: 330px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

.main-header {
  font-family: "Crimson Text", serif;
  text-align: center;
}

.main-header h1 {
  font-size: 2.9em;
  margin: 0;
}

.main-header p {
  font-size: 1.8em;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .main-header h1 {
    font-size: 1.8em;
    margin: 0;
  }
	.main-header p {
    font-size: 0.9em;
  }
}

.main-header-bar {
  position: relative;
  height: 110px;
  background: linear-gradient(to top, #dadada 10px, #cbaa5b 11px 35px, #19397d 36px);
  margin-bottom: 110px;
}

.main-header-bar img {
  position: absolute;
  height: 200px;
  transform: translate(-50%, 0);
}

.title-decoration {
  margin: 2px auto;
  height: 3px;
  width: 80px;
  background-color: #cbaa5b;
}

.sbg-cyan {
  background-color: #17a2b8;
  color: white;
}

.web-section {
  margin-top: 50px;
  padding: 40px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.justify {
  text-align: justify;
}

.font-crimson {
  font-family: "Crimson Text", serif;
}

.font-weight-bolder {
  font-weight: bolder!important;
}

.no-pointer {
  pointer-events: none;
}

.negative-margin {
  margin-top: -0.55em !important;
}

/* Error Page */
.error-page-box {
	font-family: "Open Sans", Arial, sans-serif;
	text-align: center;
  margin-bottom: 100px;
}

.error-page-box .error-code {
	color: #2e343c !important;
	font-weight: 700 !important;
	font-size: 160px;
	line-height: 1em;
	text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.error-page-box .error-title {
	text-transform: uppercase;
	color: #2e343c !important;
	font-weight: 700 !important;
	font-size: 30px;
	line-height: 1.3em;
	margin-bottom: 10px !important;
}

.error-page-box .error-description {
	color: #666e70;
	font-size: 18px;
	line-height: 1.6em;
	margin-bottom: 20px;
	text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.error-description span {
	color: #2e343c !important;
	font-weight: 700 !important;
	font-size: 20px;
	line-height: 1em;
	text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 7px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #17a2b8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  margin: 100px 0;
}

.copyright {
	color: #2e343c !important;
	font-weight: 700 !important;
	text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

#qr-image {
  padding: 36px;
}

.login-box {
  display: flex;
  flex-direction: row;
}

.login-box .form-group {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 180px;
  padding: 20px;
  border: #282c343F 1px solid;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}