@media only screen and (max-width: 767px) {
	.mobile-only {
		display: block;
	}
	.pc-only {
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	.mobile-only {
		display: none;
	}
	.pc-only {
		display: block;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.title-text {
		font-size: 1rem;
	}
	.responsive-text {
		font-size: 0.9rem;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.title-text {
		font-size: 1.58rem;
	}
	.responsive-text {
		font-size: 1.2rem;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.title-text {
		margin-top: 15px;
		font-size: 1.4rem;
	}
	.responsive-text {
		font-size: 1.2rem;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.title-text {
		margin-top: 13px;
		font-size: 1.5rem;
	}
	.responsive-text {
		font-size: 1.5rem;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.title-text {
		margin-top: 13px;
		font-size: 1.5rem;
	}
	.responsive-text {
		font-size: 2rem;
	}
}
